.etfContent {
  line-height: 2.4;
  margin: auto;
  margin-bottom: 2rem;
}
.etfImg {
  height: 102%;
}
.etfBanner {
  width: 75rem;
  margin: auto;
}
.etfBannerImg {
  width: 100%;
}
.etfWrapper {
  width: 100%;
  margin: auto;
}
.etfTextWrapper {
  margin-top: -1rem;
  position: relative;
  z-index: 10 !important;
  line-height: 1.25;
}.dropdown {
  position: relative;
  display: inline-block;
  width: 400px;

}

.dropdown-button {
  border:1px solid #868686;;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  background-color: white;
}

.dropdown-content {
  border:1px solid #868686;;
  display: flex;
  width: 100%;
  flex-direction: column;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1;
}
.dropdown {
  position: relative;
  display: inline-block;
  width: 400px;
}

.dropdown-button {
  border: 1px solid #868686;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  background-color: white;
}

.dropdown-content {
  border: 1px solid #868686;
  display: flex;
  width: 100%;
  flex-direction: column;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1;
}
.th_waran {
  font-size: 10px;
  font-weight: 400;
  display: table-cell;
  vertical-align: middle !important;
  border-right: 1px solid transparent !important;
  text-align: center;
}
.blue-border {
  /* border: 1px solid #0271bf; */
}
.table-warrant td {
  /* border-left: 1px solid #0271bf; */
}
.tableWarrantHeaderFont {
  font-size: 25px;
}
.tableWarranFontContent {
  font-size: 10px;
}
.table-warrant td {
}
.table-striped.table-warrant > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: #f2f2f2;
  color: var(--bs-table-striped-color);
}

.table-warrant > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 0px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.noLeftBorder {
  border-left: 0px solid transparent !important
    ;
}
.table-warrant {
  border-collapse: separate;
  border-spacing: 0px;
  /* border-color: #0271bf; */
  /* border-bottom: none; */
  /* border-bottom-color: transparent; */
}
.table-striped.table-warrant > tbody > tr:nth-of-type(even) {
  --bs-table-accent-bg: #e5e2e2;
  color: var(--bs-table-striped-color);
}
.table-pengumuman.table-warrant > tbody > tr:nth-of-type(even) {
  --bs-table-accent-bg: #d4edfb;
  color: var(--bs-table-striped-color);
}
.table-pengumuman.table-warrant > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: #eef9ff;
  color: var(--bs-table-striped-color);
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid black;
}
.etfContainer {
  display: flex;
  /* height: 35rem; */
  width: 65%;
  margin: auto;
  margin-bottom: 6.7rem;
  /* margin-top:2rem; */
  align-items: center;
  /* justify-content: center; */
  position: relative;
}
.etfDownloadButtonContainer {
  width: fit-content;
}
.downloadImg {
  cursor: pointer;
  margin-right: 2rem;
}
.etfDownloadGroup {
  margin-top: 4rem;
}
.noLeftBorder {
  border-left: 0px solid transparent !important;
}
.hidden {
  color: transparent;
}
.listActive {
  color: white;
  background-color: #0071bf;
}
.fixedTableCol {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
  left: 0px;
  /* border-right: 1px solid #0271bf ; */
  border-collapse: separate !important;
}
.tableFaqWarrant3 th {
  font-weight: 600 !important;
}
.waranFaqWrapper {
  height: auto;

  border-radius: 15px;
  transition: max-height 400ms ease-in-out;
  width: 55%;
  /* position: absolute; */
  z-index: 1;
  background-color: white;
  border: 3px solid #707070;
  margin: auto;
  margin-bottom: 2rem;
}
.waranImgContainer {
  height: 80%;
  /* margin-left:5rem; */
  text-align: left;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2 !important;
  right: 0;
}
.etfTextWrapper {
}
.etfTextContainer {
  height: auto;
  width: auto;
  /* margin-left: 3rem;
    margin-top: 2.5rem;
   */
  vertical-align: middle;
  /* display: flex; */
  justify-content: center;
  align-items: center;

  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
#faqBackButton {
  /* z-index: 999; */
  position: sticky;
  top: 215px;
  text-align: right;
  left: 50px;
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; */
  transition: 0.3s;
}
#faqBackButton:hover {
  background-color: #0071bf !important;
  border-color: #0071bf !important;
  color: white;
}
.subPathDokumen:hover {
  color: white;
  cursor: pointer;
  background-color: #0067ba;
  /* font-family: TypoldBold; */
  color: white !important;
  border-color: #0067ba;
  font-weight: 700;
}
.subPathDokumen {
  font-size: 14px;
  /* transition: 0.23s ; */
  /* background-color: #f2f2f2; */
  border: 1px solid #787878;
  width: 18.5%;
  padding: 0.35rem 0rem 0.33rem 0rem;
  color: #787878;
}
#faqBackButton:hover .faqBackArrow {
  filter: invert(100%);
}
.faqBackArrow {
  transform: rotate(90deg);
  height: 0.7rem;
  margin-right: 0.4rem;
  filter: invert(46%) sepia(0%) saturate(0%) hue-rotate(154deg) brightness(101%)
    contrast(92%);
}
@media only screen and (max-width: 1600px) {
  .putvscall {
    width: 95% !important;
  }
}
@media only screen and (max-width: 1300px) {
  .etfTextContainer {
    margin-left: 2rem;
  }
  .etfContainer {
    /* height: 30rem; */
  }
  .etfImg {
    height: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .putvscall {
    display: block !important;
  }
  .etfContainer {
    /* height: 25rem; */
    margin-top: 1.5rem;
  }
  .etfTextContainer {
    margin-left: 0rem;
  }
  .waranFaqWrapper {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .subPathDokumen {
    width: 90%;
    font-size: 8px;
    margin-top: 1rem;
  }
  .waranFaqTitle {
    display: flex !important    ;
  }
  .etfContent {
    width: 90%;
  }
  .etfBanner {
    width: 90%;
  }
  .waranImgContainer {
    /* display: none; */
    left: 0;
    margin-left: 40%;
    bottom: 0;
    margin-bottom: 22%;
  }
  .etfImg {
    position: absolute;
    bottom: 0;
  }
  .etfImg {
    height: 50%;
  }
  .etfDownloadButtonContainer {
    text-align: center;
    /* margin: auto; */
  }
  .downloadButtonGroup {
    /* text-align: center; */
  }
  .etfTextWrapper {
    margin-top: 3rem;
    padding-left: 2rem;
  }
  .etfContainer {
    /* width: 100%;
        margin-top: -2rem;
        text-align: center; */
    margin-top: 0rem;
    width: auto;
    margin-right: auto;
    width: 100vw !important;
  }
  .etfTextContainer {
    margin-top: 0rem;
  }

  .syariahTextWrapper
    .mx-auto.monsterratBlack.blue-btn.rounded-pill.koinsDownload.downloadButton.f1r.px-5 {
    font-size: 0.5rem;
  }
}
