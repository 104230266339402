.syariahPageWrapper {
  margin-bottom: 7rem;
}

.koinsSyariahImg {
  height: 11rem;
  margin-top: -2.5rem;
  margin-left: -3rem;
}
.koinsSyariahImgContainer {
  /* width: 55%; */
  /* margin: auto; */
}
.syariahButton:hover {
  color: #166837 !important;
  background-color: white !important;
  border-color: #166837;
}
.syariahButton {
  background-color: #166837 !important ;
  border: 3px solid #166837 !important;
  color: white;
}
.syariahTextContainer {
  width: 100% !important;
  position: relative;
  padding-left: 2.5rem;
  z-index: 10;
}
.syariahBanner {
  position: relative;
  width: 68%;
  margin: auto;
  margin-top: -1rem;
}
.bg-green {
  background-color: #eaf9f5;
}
.green-text {
  color: #166837;
}
.syariahImgContainer {
  position: absolute;
  right: 0;
}
.monsterratBlack.nowrap.f3r.green-text {
  padding-top: 3rem;
}
.syariahImg {
  height: 110%;
  right: 0;
  margin: auto;
  position: absolute;
  z-index: 3;
}
.langkahSyariah {
  margin-top: 0rem !important;
}
.syariahTextWrapper {
  line-height: 1.8;
  /* line-height: var(--unnamed-line-spacing-30); */
  letter-spacing: var(--unnamed-character-spacing-0-12);
  color: var(--unnamed-color-787878);
  width: 60%;
  margin: auto;
}
.syariahPdfButton {
  border: 2px solid #707070;
  border-radius: 20px;
  opacity: 1;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  background-color: white;
  transition: 0.2s;
}

.syariahButtonWrapper {
}
.syariahPdfButton:hover {
  color: white;
  background-color: #166837;
  border-color: #166837;
  /* width: 100%; */
}
@media only screen and (max-width: 1500px) {
  .syariahTextWrapper {
    width: 75%;
  }
}
@media only screen and (max-width: 1300px) {
  .syariahImg {
    height: 70%;
    margin-left: 50%;
  }
  .syariahPdfButton {
    margin-top: 0.5rem;
  }
  .syariahTextWrapper {
    width: 85%;
  }
  .syariahButtonWrapper {
    max-width: 95vw !important;
  }
}
@media only screen and (max-width: 1000px) {
  .syariahPdfButton {
    margin-top: 0.5rem;
    width: 22rem;
  }
}
@media only screen and (max-width: 768px) {
  .syariahTextWrapper.mt-5 {
    margin-top: 1rem !important;
  }
  .syariahTextWrapper {
    width: 100%;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
  .syariahImgDivContainer {
    /* text-align: center; */
  }
  .syariahImg {
    height: 70%;
    /* left: 0;
        margin-left: 32%; */
    top: 0;
  }
  .koinsSyariahImgContainer {
    /* text-align: center;
        margin: auto; */
    /* width: 80%; */
  }
  .koinsSyariahImg {
    height: 5.4rem;
    margin-left: -5%;
    margin-top: -1rem;
  }

  .syariahPdfButton {
    margin-top: 1rem;
    width: 19rem !important;
  }
  .syariahTextContainer {
    padding-left: 1rem !important;
    /* padding-top: 3rem; */
  }
  .syariahSmallText {
    font-size: 0.7rem;
    margin-top: -0.5rem !important;
    line-height: 1.7;
  }
  .syariahBanner {
    margin-top: 0px;
  }
  .syariahTitle {
    line-height: 1.3;
    padding-top: 1.7rem !important;
  }
  .syariahDownload {
    margin-top: 1rem;
  }
  .syariahPageWrapper {
    margin-bottom: 3rem;
  }
}
@media only screen and (max-width: 500px) {
  .syariahImg {
    height: 70%;
    left: 0;
    margin-left: 32%;
    top: 0;
  }
}
