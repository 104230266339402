.swiper-bond .swiper-wrapper{
  justify-content: space-evenly ;
}
.swiperBondContainer {
  /* height: 400px; */
  max-width: 1100px;
}
.swal2-container {
  transition: 0.4s;
}
.bondPopup {
  width: 800px;
}
.bond-card__container {
  /* width: 800px; */
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 24px;
  font-family: "Arial", sans-serif;
  color: #333;
  position: relative;
  overflow: hidden;
}

.bond-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.bond-card__header-left {
  display: flex;
  align-items: center;
}

.bond-card__icon {
  background-color: #ffd700;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
}

.bond-card__title {
  margin-left: 12px;
}

.bond-card__series {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.bond-card__series-code {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.bond-card__close-button {
  background: none;
  border: none;
  font-size: 52px;
  font-weight: 500;
  color: #888;
  cursor: pointer;
}

.bond-card__details {
  display: flex;
  justify-content: space-between;
}

.bond-card__details-left,
.bond-card__details-right {
  flex: 1;
}

.bond-card__details-left p,
.bond-card__details-right p {
  margin: 12px 0;
  font-size: 14px;
  line-height: 1.5;
}

.bond-card__details-left span,
.bond-card__details-right span {
  /* font-weight: bold; */
  /* color: #555; */
}

.bond-card__details-right {
  text-align: right;
}

.bondIstilahDiv {
  display: flex;
  flex-wrap: wrap;
}
.bondTermImg {
  max-width: 100px;
  margin-bottom: 2rem;
}
.bond-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 34px;
  background: #ffffff;
  border-radius: 20px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  font-family: Arial, sans-serif;
  max-width: 1000px;
  margin: auto;
  box-shadow: 4px 4px 8px -1px rgba(0, 0, 0, 0.18);
  border: 1px solid #e7e7e7; /* Stroke with #E7E7E7 at 100% opacity */
  cursor: pointer;
  transition: 0.1s;
  margin-top: 0.4rem;
  margin-bottom: 1rem;
}
.bond-card:hover {
  filter: brightness(98%);
}
.bondIstilah {
  flex-basis: 33%;
}
/* Header: Logo and Bond Info */
.bond-header {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}

.bond-logo .logo-circle {
  background: #ffce00;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.bond-logo img {
  width: 60px;
  height: 60px;
}

.bond-info {
  display: flex;
  flex-direction: column;
}

.bond-title {
  font-size: 16px;
  color: #6e6f72;
}

.bond-series {
  font-size: 20px;
  font-weight: bold;
}

/* Bond Details */
.bond-details {
  display: flex;
  gap: 40px;
}
.bypass3{
  margin-top: -3rem;
}
.bond-detail {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.label {
  font-size: 16px;
  color: #6e6f72;
}

.value {
  font-size: 16px;
  font-weight: bold;
}

.highlight {
  color: green;
  font-size: 18px;
}
.accordion-item {
  border-bottom: 3px solid #ccc;
  border-radius: 14px;
}

.accordion-header {
  padding: 15px;
  background-color: #f7f7f7;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.accordion-header:hover {
  background-color: #e0e0e0;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  padding: 0 15px;
  background-color: #fff;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.accordion-item:hover .accordion-content {
  max-height: 100px; /* Adjust based on content height */
  padding: 15px;
}
.accordion-header:hover span {
  transform: rotate(90deg);
  background-color: #0071bf;
}

.accordion-header:hover span:last-child {
  left: 50%;
  right: 50%;
}

@media only screen and (max-width: 1400px) {
  .imageDivWrapper {
    width: 70% !important;
  }
  .sahamBannerText {
    font-size: 3em !important;
  }
}
@media only screen and (max-width: 1300px) {
  .productBanner {
    width: 80% !important;
  }.bypass3{
    margin-top:0rem
  }
}
@media only screen and (max-width: 1200px) {
.bypass3{
  margin-top:0rem
}
}
@media only screen and (max-width: 1100px) {
  .imageDivWrapper {
    width: 80% !important;
  }
  .productBanner {
    width: 100% !important;
  }
}
@media only screen and (max-width: 1000px) {
  .bond-details {
    /* flex-basis: 45%; */
  }
 
  .bond-logo.block1000 {
    margin-bottom: 1.2rem;
  }
  .text-center-1000 {
    text-align: center;
  }
  .bond-header {
    padding-right: 1rem;
  }
  .bond-series.monsterratBold {
    /* font-size: 24px !important; */
  }
}
@media only screen and (max-width: 768px) {
  .bond-header{
    gap:2px !important;
    padding-right:0.2rem !important
  }
  .bond-details{
    gap:12px !important
  }
  .label.monsterratRegular {
    font-size: 9.5px !important;
  }
  .bond-series.monsterratBold {
    font-size: 16px !important;
  }
  .value.highlight.monsterratBold{
    font-size: 16px !important;
  }
  .swiperBondContainer {
    height: 250px !important;
    max-width: 1200px;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
.bond-card{
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.bond-logo img {
    width:26px;
    height:26px;
  }
  .value.monsterratMedium{
    font-size: 12px !important;
  }
  .bond-logo .logo-circle {background-color: transparent;}
  .imageDivWrapper {
    width: 100% !important;
  }
  .downloadButton {
    width: auto;
  }

  .sahamBannerText {
    font-size: 2em !important;
  }
  .productBanner {
    width: 100% !important;
  }
  .bannerTitle {
    font-size: 1.8rem !important;
  }
  .bannerSubtitle {
    font-size: 0.5rem !important;
  }
  .produkMobileContainer {
    padding-top: 7rem !important;
  }
}
