.sahamTable{
    max-width: 80%;
    margin:auto;
    margin-top:5rem;
    margin-bottom:4rem
}

.jenisAkunContainer{
    position: relative;
    z-index: 99;
}
.imageDivWrapper {
    background-size: 100% auto;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    color: red;
    z-index: 10;
    margin: auto;
    width: 75%;

}
.table-bordered.tableFaqWarrant>:not(caption)>*>* {
    border-width: 0 2px !important;
}
.regAccSaham{
    width: 100%;
}
.imageZ1 {
    width: 100%;
    height: auto;
    /* position: relative; */
    z-index: 1;
  
}
.insideWrapperAbsolute{
    position:absolute;
  bottom: 0;
    z-index: 9;

}
.sahamImgDiv{
    left:0;
    margin-left: 7.2%;
    margin-bottom: 12%;
}
.sahamTableHead{
    width: 50% !important;
    border: 1px solid black;
    padding:15px !important;
    text-align: center;
    font-weight: 400 !important;
}
.sahamBannerText{
    color:#0071bf;
    font-size: 4em;
    margin-bottom: 27%;
    margin-left:7%
  }
  .sahamBannerTitle{
  line-height: 1.25 ;
  }
  .sahamBannerSubtitle{
    color:#0071bf;
  }
/* td{
    border: none !important;
    border-left: 1px solid black !important;
} */
.sahamImageContainer{
    width: 70%;
    background-image: url('../../../assets/saham.jpg');
    height: 0;
    padding: 0; /* remove any pre-existing padding, just in case */
    padding-bottom: 75%; /* for a 4:3 aspect ratio */

    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.sahamTextContainer{
    width: 60%;
    margin: auto;
    margin-bottom: 5rem;
    line-height:2;
}
.sahamTextWrapper{
    display: grid;
    grid-template-columns: 50% 50%;
    width: 95%;
    margin:auto;
    margin-bottom: 8rem;
}
.sahamText{
    border:4px solid 
    #0071bf;
    background-color: 
    #f9fdff;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    
}
.sahamTableContent{
margin-top:1rem;
color: #787878;
}
.sahamContainer{
    line-height: 2;
}
.sahamContainer{
    margin-top: 0rem    !important;
}
.tableBottom{
    border-bottom: 1px solid black !important;
}
@media only screen and (max-width: 1500px) {
    .sahamTextWrapper{
        width: 90% !important;
      }
      .sahamText{
        border:4px solid 
        #0071bf;
        background-color: white;
        padding-left: 2rem;
        padding-right: 2rem;
        
    }
    .jenisAkunContainer{
        width: 75%;
    }
  
}
@media only screen and (max-width: 1200px) {
    .sahamTextContainer{
        width: 95% !important;
      }
      .jenisAkunContainer{
        width: 85%;
      }
}
@media only screen and (max-width: 900px) {
    .sahamTextWrapper{
        display: block;
    }
    .sahamText{
        padding-top: 1.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 1rem;
    }
}
@media only screen and (max-width: 768px) {
    .sahamTextTitle{
        font-size: 1.5rem !important;
    }
    .sahamImgDiv{
        margin-bottom: 25%;
    }
    .sahamBannerText{
        font-size:2.5rem !important;
        margin-bottom: 67%;
        width: fit-content;
    }
    .langkahPendaftaranText{
        font-size: 0.82rem !important;
    }
    .imageDivWrapper{
        /* margin-top: -1rem; */
    }
    .jenisAkunContainer{
        width: 100%;
        /* margin-top: -3rem !important; */
    }
    .sahamBannerSubtitle{
        font-size: 0.8rem !important;
    }
    .sahamDivWrapper{
        /* margin-top: ; */
    }
    .downloadButton{
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important;
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
        height:1.6rem;
    }
    .sahamPlaneImg{
        width: 110%;
    }
    .sahamPlane{
        margin-left:48%;
        margin-bottom: 40%;
    }
    .sahamImgDiv{
        margin-left: 2rem !important;
    }
    .resume{
        width: 5.6rem;
    }
    .mail{
        width: 5.6rem;
    }
}
@media only screen and (max-width: 500px) {
    .sahamBannerText{
        font-size: 1.8rem !important;
    }
}