.etfContent {
  line-height: 2.4;
  margin: auto;
  margin-bottom: 2rem;
}
.etfImg {
  height: 102%;
}
.etfBanner {
  width: 75rem;
  margin: auto;
}
.etfBannerImg {
  width: 100%;
}
.etfWrapper {
  width: 100%;
  margin: auto;
}
.etfTextWrapper {
  margin-top: -1rem;
  position: relative;
  z-index: 10 !important;
  line-height: 1.25;
}
.etfContainer {
  display: flex;
  /* height: 35rem; */
  width: 65%;
  margin: auto;
  margin-bottom: 6.7rem;
  /* margin-top:2rem; */
  align-items: center;
  /* justify-content: center; */
  position: relative;
}
.etfDownloadButtonContainer {
  width: fit-content;
}
.downloadImg {
  cursor: pointer;
  margin-right: 2rem;
}
.etfDownloadGroup {
  margin-top: 4rem;
}
.etfImgContainer {
  height: 100%;
  /* margin-left:5rem; */
  text-align: left;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2 !important;
  right: 0;
}
.etfTextWrapper {
}
.etfTextContainer {
  height: auto;
  width: auto;
  /* margin-left: 3rem;
    margin-top: 2.5rem;
   */
  vertical-align: middle;
  /* display: flex; */
  justify-content: center;
  align-items: center;

  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
@media only screen and (max-width: 1300px) {
  .etfTextContainer {
    margin-left: 2rem;
  }
  .etfContainer {
    /* height: 30rem; */
  }
  .etfImg {
    height: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .etfContainer {
    /* height: 25rem; */
    margin-top: 1.5rem;
    width: 80% !important;
  }
}
@media only screen and (max-width: 768px) {
  .etfContent {
    width: 90%;
  }
  .etfBanner {
    width: 90%;
  }
  .etfImgContainer {
    /* display: none; */
    left: 0;
    margin-left: 40%;
    bottom: 0;
    margin-bottom: 22%;
  }
  .etfImg {
    /* position: absolute; */
    bottom: 0;
  }
  .etfImg {
    height: 50%;
  }
  .etfDownloadButtonContainer {
    text-align: center;
    /* margin: auto; */
  }
  .downloadButtonGroup {
    /* text-align: center; */
  }
  .etfTextWrapper {
    margin-top: 2rem;
    padding-left: 2rem;
  }
  .etfContainer {
    /* width: 100%;
        margin-top: -2rem;
        text-align: center; */
    margin-top: 0rem;
    width: auto;
    margin-right: auto;
    width: 100%;
    margin-bottom: 1rem !important;
  }
  .etfTextContainer {
    margin-top: 5rem;
  }

  .syariahTextWrapper
    .mx-auto.monsterratBlack.blue-btn.rounded-pill.koinsDownload.downloadButton.f1r.px-5 {
    font-size: 0.5rem;
  }
}

@media only screen and (max-width: 508px) {
  .etfImg {
    /* position: absolute; */
    top: 86px;
  }
}
