.jenisReksadanaContainer {
  width: 70%;
  /* margin-bottom: 8rem; */
  margin-top: 5rem;
}
.reksadanaImg {
  width: 62%;
}
.reksadanaBlueContainer {
  text-align: left;
  padding-left: 9rem;
  padding-bottom: 6rem !important;
}
.reksadanaDescriptionWrapper {
  display: flex;
}
.reksadanaDescription {
  /* height: 10rem; */
  width: 100%;
  box-shadow: 0 0px 24px rgb(0 0 0 / 15%);
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 17px;
  margin: auto;
}
.reksadanaMascotImg {
height: 34rem;
margin-right: 4%;
}
.reksadanaMascotImg{
  position: relative;
text-align: right;
}
.insideWrapperAbsolute.reksadanaMascot{
  text-align: right;
  width: 100%;
  z-index: -1;

}
.mitraLogoWrapper{
  margin-top: 4rem;
}
.reksadanaDescriptionTxt {
  letter-spacing: 0.12px;
  color: #787878;
  opacity: 1;
}
.reksadanaShadow {
}
.risikoReksadana {
  width: 60%;
  /* margin-top: -10rem; */
  padding-left: 5rem;
  margin-bottom: 10rem;
}
.flowContainer {
  width: 100%;
  margin-bottom: 10rem;
}
.flowWrapper {
  position: relative;
  max-width: 65%;
  margin: auto;
}
.reksadanaInnerImg {
  margin-left: 5%;
  margin-bottom: 3%;
}
.flowMiddleBorder {
  position: absolute;
  width: 50%;
  height: 100%;
  border-right: 5px solid #0071bf;
  /* background-color: green; */
  z-index: 4;
}
.flowSpacer {
  height: 4rem;
  margin: auto;
  /* background-color: red; */
  z-index: 10;
}
.flowFlexContainer {
  display: flex;
}
.flowFlexDiv {
  border-radius: 30px;
}
.flowFlexContainerSpacer {
  height: 50% !important;
  border-bottom: 2px solid #0071bf;
  width: 5rem;
}
.flowPadding {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
.flowHalf {
  width: 50%;
  height: auto;
  display: flex;
}
.halfLeft {
  justify-content: flex-end;
  position: relative;
  right: 0;
}
.flowBottomContainer {
  display: grid;
  grid-template-columns: 40% 20% 40%;
}
.bottomMiddleSpacer {
  z-index: 6;
  position: relative;
  background-color: white;
}
.flowBottomSpacer {
  width: 60%;
  height: 10rem;
  background-color: white;
  z-index: 5;
  position: relative;
  margin-top: -50px;
  border: 2px solid #0071bf;
  border-bottom: none;
}
.flowBoxBorder {
  border-radius: 30px;
  border: 5px solid #0071bf;
  width: fit-content;
  max-width: 60%;
  z-index: 9;
  background-color: white;
  position: relative;
}
.reksadanaPointContainer {
  padding-right: 7rem;
}
.reksadanaDescriptionContainer {
  height: 0;
  visibility: hidden;
  opacity: 0;
  margin: auto;
  transition: 0.4s;
  width: 130%;
  margin-left: -15%;
  position: relative;
  z-index: 99;
}
.reksadanaUpperContainer {
  text-align: center;
  width: 60%;
  margin: auto;
  margin-top: 3rem;
}
.reksadanaBannerText {
  color: #0071bf;
  font-size: 3.5rem;
  margin-bottom: 13%;
  margin-left: 5%;
}

.reksadanaBannerTitle {
  line-height: 1.25;
}
.reksadanaBannerSubtitle {
}
.reksadanaHover:hover ~ .reksadanaDescriptionContainer {
  visibility: visible;
  opacity: 1;
  /* margin-top: ; */
}

.reksadanaImgBorder {
  height: 11rem;
  width: 11rem;
  border: 7px solid #0071bf;
  border-radius: 50%;
  margin: auto;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reksadanaImgWrapper {
  display: flex;
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
}
.reksadanaImgContainer {
  width: 20%;
  /* margin-bottom: 4rem; */
}
.reksadanaShadow {
  /* box-shadow: 0 4px 14px -14px gray; */
  height: auto;
}
.reksadanaImgDescription {
  font-size: 1.5rem;
  width: 90%;
  margin: auto;
  line-height: 150%;
  margin-top: 2rem;
  text-align: center;
  min-height: 6rem;
}
.mitra {
  clip-path: polygon(100% 0, 0% 100%, 0 0);
  height: 400px;
  width: 400px;
}
.container {
  position: relative;
}

.line {
  border-top: 1px solid black; /* You can adjust the color, thickness, and style as needed */
  width: 100%;
}
.diagonalBackground {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 10 10'> <path d='M0 10 L10 0' stroke='black' stroke-width='0.01' /></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;

  width: 520px;
  height: 520px;
  position: absolute;
  margin-left:3rem
}
.mitraText{
margin-top:8rem
}
.mitraWrapper{
  /* margin-top: 19rem;
  width: 60%; */
  margin-bottom: 9rem;
}
.reksadanaBg{
  width: 100%;
}
.amLogo{
  height: 2.25rem;
  /* margin-bottom:4rem; */
}
.kisiAm{
  /* margin-left: 56%; */
  /* margin-top: 1rem; */
  /* margin-bottom:4rem; */
}
.capitalAm{
  /* margin-left: 48%; */
  height: 2.3rem;
  /* margin-bottom:3.75rem; */
}
.starAm{
  /* margin-left: 27%; */
  height: 4.66rem;
  /* margin-bottom: 3.4rem; */
}
.avristAm{
  /* margin-left: 19%; */
  height: 4.66rem;
}
.reksadanaImgLarge{
  height: 35rem;
} 
.pinnacleAm{
  /* margin-left: 37%; */
  height: 4.75rem;
  /* margin-bottom:3rem; */
}
.insightAm{
  height: 7rem;
}
@media only screen and (max-width: 1500px) {
  .reksadanaUpperContainer{
    width: 80% !important;
  }
  .jenisReksadanaContainer{
    width: 85%;
  }
  .reksadanaImgWrapper{
    width: 100%;
  }
  .risikoReksadana{
    width: 80%;
  }
  .mitraWrapper{
    width: 80%;
  }
  .reksadanaBannerText{
    margin-left: 0;
  }
  .reksadanaMascotImg{
    margin-right: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .reksadanaImgWrapper {
    width: 75%;
  }
  .reksadanaImgDescription {
    font-size: 1.2rem;
    width: 80%;
  }
  .reksadanaImgBorder {
    height: 6rem;
    width: 6rem;
  }
  .mitraWrapper{
    width: 100%;
  }
}
@media only screen and (max-width: 1100px) {
  .reksadanaUpperContainer{
    width: 90% !important;
  }
  .risikoReksadana{
    width: 90%;
  }
}
@media only screen and (max-width: 1000px) {
  .reksadanaImgWrapper {
    width: 95%;
  }
  .amLogo{
    height: 2.25rem;
    /* margin-bottom: 9rem; */
  }
  .kisiAm{
    /* margin-left: 58%; */
    /* margin-top: 2rem; */
  }
  /* .capitalAm{
    margin-left: 32.5%;
    height: 2rem;
    margin-bottom: 8.3rem;
  } */
  /* .starAm{
    margin-left: 17%;
    height: 5rem;
  } */
  .reksadanaBannerText{
    margin-bottom: 25%;
  }
}
@media only screen and (max-width: 768px) {
  .reksadanaImgLarge{
    height: 35rem;
    height: 27rem;
  }
  .reksadanaImgWrapper {
    
    pointer-events: none;
  }
  .notFoundContainer {
    display: block;
  }
  .reksadanaImgWrapper {
    width: 99%;
    margin: auto;
    justify-content: center;
  }
  .notFoundImg {
    max-width: 90%;
    height: auto;
    margin-top: -7rem;
  }
  .notFoundImageContainer {
    height: auto;
  }
  .comingSoonBigText {
    font-size: 3rem;
  }
  .comingSoonSmallText {
    font-size: 1.5rem;
  }
  .innerNotFoundRight {
    margin-left: 0rem;
    padding: 1rem;
  }
  .reksadanaImgDescription {
    font-size: 0.8rem;
    width: 90%;
  }
  .reksadanaImgContainer {
    width: 33%;
  }
  .reksadanaImgBorder {
    width: 6rem;
    height: 6rem;
  }
  .reksadanaBannerText {
    margin-bottom: 48%;
  }
  .reksadanaBannerSubtitle {
    white-space: normal;
    font-size: 0.5rem !important;
    margin-top: 1rem !important;
  }
  .reksadanaBannerTitle {
    font-size: 1.8rem;
    width: fit-content;
  }
  .reksadanaUpperContainer {
    width: 95% !important;
    margin-top: 0rem;
  }
  .reksadanaBlueContainer {
    padding-left: 0rem;
  }
  .reksadanaPointContainer {
    padding-left: 2rem;
    padding-right: 1rem;
  }
  .reksadanaDescriptionContainer {
    margin-top: -5rem;
  }
  .reksadanaImgContainer {
    margin-bottom: 2rem;
  }
  .risikoReksadana {
    width: 100%;
    padding-left: 2rem;
  }
  .risikoTitle {
    text-align: center !important;
  }
  .flowWrapper {
    width: 100%;
    max-width: 95%;
    font-size: 0.8rem;
  }
  .flowBottomSpacer {
    height: 5rem;
  }
  .flowFlexDiv {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    border-radius: 15px !important;
  }
  .reksadanaMascotImg {
    top:4rem;
 height: 20rem;
    display: block;
  }
  .reksadanaMascot {
    margin-left: 58%;
    margin-bottom: 42%;
  }
  .reksadanaInnerImg {
    margin-bottom: 16%;
    margin-left: 2rem !important;
  }
  .jenisReksadanaContainer {
    width: 100%;
  }
  .flowBoxBorder {
    border-radius: 10px;
  }
  .flowPadding {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .reksadanaBannerText {
    margin-left: 2rem !important;
  }
  .flowFlexContainerSpacer {
    width: 0rem;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .risikoReksadana {
    width: 95%;
    padding-left: 0rem !important;
    margin: auto;
  }
  .reksadanaContainer{
    margin-top: 5rem;
  }
  .reksadanaImgLarge{
    /* padding-top: 8rem !important */
  }
  .amLogo{
    height: 2.25rem;
    margin-bottom: 3rem;
  }
  /* .pinnacleAm{
    margin: auto;
    margin-bottom: 3rem;
    height: 3.3rem;
  } */
  .mitraWrapper{
    margin-top: 4rem;
  }
  /* .kisiAm{
   margin: auto;
   height: 2rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  .capitalAm{
    margin: auto;
    height: 1.5rem;
    margin-bottom: 3rem;
  }
  .starAm{
    margin: auto;
    height: 3.6rem;
  } */
  .diagonalBackground{
    width:300px;
    height:300px;
    margin-left: 0rem;
  bottom: 30rem;
  display: none;
  }
  .mitraText{
    margin-left: -3rem;
  }
  .mitraLogoWrapper{
    text-align: center;
  }
}
